body {
    margin: 0;
    font-family: "Castoro", serif;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    height: 100%;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    @apply transition-colors
}
